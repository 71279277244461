
import { Component, Mixins } from 'vue-property-decorator';
import mixinEmployeeEachPart from '@/mixins/mixinEmployeeEachPart';
import { Inject } from '@/library/provide';
import LItem from "@/components/l/Item.vue";
import FText from "@/components/f/Text.vue";

@Component({
  components: {
    LItem,
    FText,
  }
})
export default class Main extends Mixins(mixinEmployeeEachPart) {
  created() {
    this._title = 'pass';
    this._need = true;
  }

  get condition() {
    return !!this.is_edit;
  }
}
